import React from 'react';
import '../../TrailScreen.css';

const EverifierCards = ({data}) => {
    
  return (
    <div className='align-items-center d-flex flex-column gap-4'>
    {
        data.map((item, index)=>(
            <div key={index} className='bg-white p-3 rounded-2' >
            <img src={item.imgSrc} alt="image" />
            <h3>{item.title}</h3>
            <p className='text-secondary'>{item.detail}</p>
            </div>
        ))
    }
        

    </div>
  )
}

export default EverifierCards