import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../pages/loader/SimpleLoader";
import { Helmet } from "react-helmet";

export default function TrailSearchCompany() {
  const [CompanyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [trailLeft, setTrailLeft] = useState(0);
  const [CompanyInfo, setCompanyInfo] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('find_email');
  const [isLogin, setIsLogin] = useState();

  useEffect(() => {
    setIsLogin(token?.length > 0 ? true : false);
  }, []);

  const handelSearchCompany = (e) => {
    e.preventDefault();

    if (CompanyName === "") {
      toast.error("Please enter company name");

      return;
    } else {
      const TrailCompanySearch = Cookies.get("TrailCompanySearch") || 0;

      if (+TrailCompanySearch > 4 && !isLogin) {
        navigate("/signup");
        toast.error("Limit Reached");
      } else {
        Cookies.set("TrailCompanySearch", +TrailCompanySearch + 1, { expires: 365 });
        setTrailLeft(trailLeft + 1);
        navigate(`/c/${CompanyName.replace(/\s+/g, "-")}`);
      }
      
    }
  };

  return (
    <>
     <Helmet>
        <title>Discovemail - Smart email verification tool for finding and checking emails | Top And Quick E-mail Verification For Your Business - Discovemail. | The Best Online Email Extractor And Verifier For Free - Discovemail.</title>

        <meta
          name="description"
          content="Discovemail Is A Quick E-mail Verification Tool. Get Fast & Accurate Results With Discovemail. Improve Your E-mail Deliverability. Try With Discovemail Today For Free."
        />

        <meta
          name="description"
          content="Discovemail Is The Best Online Email Extractor And Verifier For Free. Improve your email marketing with Discovemail. Ensure High-Quality Email Lists For Better Results."
        />
      </Helmet>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
            <article className={`${token ? 'col-md-6 feature-card ' : 'col-md-12 white-card ' }  col-11 px-5 py-4`}>
              <div className="">
                <h2 className="title pt-2">Find Company</h2>
                <p className="subTitle">We'll never share your email with anyone else.</p>
              </div>
              <form className="" onSubmit={handelSearchCompany}>
                <div className="input-group flex-lg-fill mb-4">
                    <input
                      type="text"
                      className="form-control w-100 py-3"
                      placeholder="Enter company name to search"
                      value={CompanyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                <div>
                    <button type="submit" className="btn btn-disco rounded-2 w-100">
                      Find company
                    </button>
                  </div>
              </form>
          </article>
        </>
      )}
    </>
  );
}
