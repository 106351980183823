
import DomainPic from '../../../assets/revampHomeassets/DomainPic.svg';
import FinderImg from '../../../assets/revampHomeassets/finderImg.svg';
import VerificationImg from '../../../assets/revampHomeassets/verifierImg.svg';
import tool1 from '../../../assets/revampHomeassets/tool1.svg';
import tool2 from '../../../assets/revampHomeassets/tool2.svg';
import tool3 from '../../../assets/revampHomeassets/tool3.svg';
import tool4 from '../../../assets/revampHomeassets/tool4.svg';
import tool5 from '../../../assets/revampHomeassets/tool5.svg';
import tool6 from '../../../assets/revampHomeassets/tool6.svg';
import email from 'assets/gmailIcon.svg';

export const heroImgData = [
    {
        id: 1,
        pic: FinderImg,
    },
    {
        id: 1,
        pic: VerificationImg,
    },
    {
        id: 1,
        pic: DomainPic,
    },
]

export const featuresData =[
    {
        heading:'Email Verifier',
        caption:"Market-leading & user-friendly email verification with exceptional customer support.",
        path: "/email-verifier",
        icon: email
    },
    {
        heading:"Domain Verifier",
        caption:"Verify the Domain of company of your likings With Confidence.",
        path: "/domain-verifier",
        icon: email
    },
    {
        heading:"Email from name & Domain",
        caption:"Trying to find out a contact within a corporation??, then you are at the right Place.",
        path: "/email-from-name-and-domain",
        icon: email
    },
    {
        heading:"Search Company",
        caption:"A Powerful tool that provides valuable insights about companies of interest.",
        icon: email,
        path: "/search-company",
    },
    {
        heading:"Bulk tasks",
        caption:"For huge lists of emails to find or verify, Bulk verifier and finder are fast and efficient way to aid you.",
        icon: email,
        path: "/bulks",
    },
    {
        heading:"Campaigns",
        caption:"Run camppaigns with Discovemail's customised plans.",
        icon: email,
        path: "/campaign",
    },
    ]

    export const toolData = [
        {
            imgSrc: tool1 ,
            title: "Ensure email deliverability." ,
            details: "We can identify emails that bounce by using our email validation software. Keep your bounce rate below 5% by regularly cleaning your list." ,
        },
        {
            imgSrc: tool2 ,
            title: "MX Record Detection" ,
            details: "Our platform indicates the presence of an MX Record for checked domains, ensuring authenticity of email addresses." ,
        },
        {
            imgSrc: tool3  ,
            title: "Free Email Verifier" ,
            details: "Quickly check email addresses to determine if they're from free, toxic, or disposable domains. Plus, receive names when available. Use it anytime for swift verification." ,
        },
        {
            imgSrc: tool4 ,
            title: "Find the email address" ,
            details: "Find the verified email address of any professional" ,
        },
        {
            imgSrc: tool5  ,
            title: "Send a cold email campaign" ,
            details: "Connect your email for creating, personalising, scheduling, and sending targeted campaigns at scale with Discovemail." ,
        },
        {
            imgSrc: tool6 ,
            title: "Verify the email" ,
            details: "Verified email address of any professional" ,
        },
    ]

    // export const toolData = [
    //     {
    //         imgSrc: tool1 ,
    //         title: "Ensure email deliverability." ,
    //         details: "We can identify emails that bounce by using our email validation software. Keep your bounce rate below 5% by regularly cleaning your list." ,
    //     },
    //     {
    //         imgSrc: tool2 ,
    //         title: "MX Record Detection" ,
    //         details: "Our platform indicates the presence of an MX Record for checked domains, ensuring authenticity of email addresses." ,
    //     },
    //     {
    //         imgSrc: tool3  ,
    //         title: "Free Email Verifier" ,
    //         details: "Quickly check email addresses to determine if they're from free, toxic, or disposable domains. Plus, receive names when available. Use it anytime for swift verification." ,
    //     },
    //     {
    //         imgSrc: tool4 ,
    //         title: "Find the email address" ,
    //         details: "Find the verified email address of any professional" ,
    //     },
    //     {
    //         imgSrc: tool5  ,
    //         title: "Send a cold email campaign" ,
    //         details: "Connect your email for creating, personalising, scheduling, and sending targeted campaigns at scale with Discovemail." ,
    //     },
    //     {
    //         imgSrc: tool6 ,
    //         title: "Verify the email" ,
    //         details: "Verified email address of any professional" ,
    //     },
    // ]