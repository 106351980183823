import React from 'react'

const ToolCards = (props) => {
  
  return (
    <div className='row pt-md-5'>
    {
      props?.toolData.map((item, index)=>(
        <div className="col-md-4 mb-4 text-center px-2" key={index}>
          <div className="icon-box">
            <img 
              src={item.imgSrc}
              alt="icons" 
              className="icon-img mb-3"
            />
            <h5>{item.title}</h5>
            <p className='lstoolText'>{item.details}</p>
          </div>
        </div>
      ))
    }
    </div>
  )
}

export default ToolCards